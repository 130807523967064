import Ably from 'ably';

let realtime = null;

export default () => {
  if (!useRuntimeConfig().public.ablyPublicKey) {
    console.warn('Ably public key not found');
    return;
  }

  if (!import.meta.client) {
    return;
  }

  if (!realtime) {
    realtime = new Ably.Realtime(useRuntimeConfig().public.ablyPublicKey);
  }

  return realtime;
};
